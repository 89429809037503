@tailwind base;
@tailwind components;
@tailwind utilities;
/* React Slick CSS */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-bounce {
  animation: bounce 1.5s infinite;
}

html, body {
  scrollbar-width: none; /* Pour Firefox */
  -ms-overflow-style: none; /* Pour IE et Edge */
}
html::-webkit-scrollbar {
  display: none; /* Pour Chrome, Safari et Opera */
}

@keyframes scroll {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(4px);
  }
}

.animate-scroll {
  animation: scroll 1.5s infinite;
}